$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    xxxl: 1520px,
    xxxxl: 1720px,
    xxxxxl: 1920px,
    xxxxxxl: 2120px,
    xxxxxxxl: 2320px,
    xxxxxxxxl: 2520px,
    xxxxxxxxxl: 2720px,
    xxxxxxxxxxl: 2920px,
    xxxxxxxxxxxl: 3120px,
    xxxxxxxxxxxxl: 3320px,
);